import { FC, useEffect, useMemo, useState } from 'react';

import 'swiper/swiper.less';
import './style.less';

interface IProps {
	classNames?: string;
}
/** 品牌信息 */
const BrandInfo: FC<IProps> = ({ classNames }) => {
	const [activeTab, setActiveTab] = useState<number>(1);

	const brandTabList = [
		{
			id: 1,
			name: '课程全',
			desc: '30门课程，门门皆精品',
		},
		{
			id: 2,
			name: '名师多',
			desc: '严选名师，个个都是大咖',
		},
		{
			id: 3,
			name: '口碑佳',
			desc: '千万用户，人人好口碑',
		},
	];

  // 用户评价
	const userCommentList = [
		{
			avatar: 'https://img.qlchat.com/qlLive/activity/image/RYP7Y9UK-9V5E-GTP9-1708918869429-HWKPASRUE9K7.png?x-oss-process=image/format,webp',
			title: '兴趣岛手机摄影训练营学员',
			userName: '简易·70后硕士宝妈',
			contentHtml: '2022年，我在兴趣岛学习手机摄影，重拾年轻时的热爱。我的6幅摄影作品成功入展了<strong>韩国仁川国际海洋摄影节</strong>，1幅作品<strong>斩获魅力中国摄影大赛二等奖</strong>。我现在还成为了视觉中国的签约供稿师，作品成功变现。愿我们都能从自己的“热爱”里得到力量和快乐！',
		},
		{
			avatar: 'https://img.qlchat.com/qlLive/activity/image/KJJV62DX-ASVX-VHHI-1708927995175-SYKLI5X216XL.png?x-oss-process=image/format,webp',
			title: '兴趣岛手机摄影训练营学员',
			userName: '刘浪汉·55岁个体户',
			contentHtml: '作为一名摄影爱好者，通过在兴趣岛手机摄影训练营的学习，我的摄影水平更上一层楼。不仅一举斩获兴趣岛<strong>手机摄影大赛城市赛道亚军</strong>，还成为<strong>香港中国旅游出版社高级签约摄影师</strong>，多张作品荣获<strong>财富人生杯北美新视觉艺术大赛奖项</strong>。只要心中有梦，并一直在路上，总有一天会实现。',
		},
		{
			avatar: 'https://img.qlchat.com/qlLive/activity/image/WS4IYCMD-HH2V-JQ6Q-1708928000678-F75CNA5UX1U6.png?x-oss-process=image/format,webp',
			title: '兴趣岛吃瘦训练营学员',
			userName: '张梅·医务工作者',
			contentHtml: '2022年，我遇到了兴趣岛吃瘦训练营，成功<strong>减脂二十多斤</strong>，而且身体的代谢能力更好了，高血压、高血脂也得到了改善。由于外形的优化，自己也逐渐找回了自信。<strong>健康吃瘦，夺回身体的主动权！</strong>',
		},
		{
			avatar: 'https://img.qlchat.com/qlLive/activity/image/9WX1GTI5-7T9N-1S4V-1708928005191-T9PSD2D63HHD.png?x-oss-process=image/format,webp',
			title: '兴趣岛吃瘦训练营学员',
			userName: '林嘉·退休人员',
			contentHtml: '一次偶然的机会，我报名了兴趣岛吃瘦训练营。经过在吃瘦营和断食营两个多月的学习，我不仅<strong>成功瘦身10公斤</strong>，身体的各项功能也恢复正常了。人生没有“退休”，换一个赛道成长，为自己的健康保驾护航。',
		},
		{
			avatar: 'https://img.qlchat.com/qlLive/activity/image/BEQULEPH-3SIM-WLSW-1708928010900-Z5NS4A875K8L.png?x-oss-process=image/format,webp',
			title: '兴趣岛穿搭营学员',
			userName: '静姐·58岁退休人员',
			contentHtml: '在刚开始学习课程时有点自卑 ，觉得自己岁数太大 ，学习能力比其他同学要低 ，但是在经过不断的鼓励下 ，她慢慢打开自己 ，与同学互动并且鼓 励其他同学一起学习 ，起到带头学习的榜样作用。 这样的改变不止在穿着打扮上体现 ，更在生活中 的方方面面也<strong>更加自信 ，乐于交友。</strong>',
		},
		{
			avatar: 'https://img.qlchat.com/qlLive/activity/image/1GBW6YHX-WVMW-VQER-1708928017505-OMLOJHYL75GJ.png?x-oss-process=image/format,webp',
			title: '兴趣岛穿搭营学员',
			userName: '章女士·32岁宝妈',
			contentHtml: '章女士在课后积极地改变自己 ，整理自己的衣橱， 找到自己的穿衣风格 ，章女士说：“<strong>改变了自己穿搭后</strong>，无论到什么地方 ，总是会收到别人的夸奖 ，这也让我有了更多的信心 ，我真的特别感谢 杜杜老师的课程 ，让我找回来自我。”',
		},
		{
			avatar: 'https://img.qlchat.com/qlLive/activity/image/2YNOVOBQ-SGXJ-PKSD-1708928022987-RG1MU5H25QP8.png?x-oss-process=image/format,webp',
			title: '兴趣岛瑜伽训练营学员',
			userName: '羽墨·70后二孩宝妈',
			contentHtml: '2022年，我报名了兴趣岛瑜伽训练营，一个月时间，我练出了肉眼可见的翘臀和马甲线，<strong>皮肤也变得紧致红润</strong>。由于我孕期胎位高，生完孩子后肋骨外翻得很厉害，现在已经基本调整回来了，身体素质也比之前好。健康与美丽掌控在自己手中的感觉，真的很好！',
		},
		{
			avatar: 'https://img.qlchat.com/qlLive/activity/image/3DLPB7AL-5A7D-7B3T-1708928028176-OGXCYJJXG972.png?x-oss-process=image/format,webp',
			title: '兴趣岛瑜伽训练营学员',
			userName: '玉潇·职场白领',
			contentHtml: '2022年，我在兴趣岛报名了瑜伽课程。坚持了18天的课程后，我肚子上的赘肉明显变少，<strong>骨盆前倾也逐渐回正</strong>，腰痛问题减轻，皮肤也变得更加细腻有弹性，变得更加快乐自信，慢慢回到了年轻时的状态。愿每一位女性都能在学习中发现焕然一新的自我，迎来积极快乐的生活。',
		},
		{
			avatar: 'https://img.qlchat.com/qlLive/activity/image/IHIAFJPO-CELG-I82N-1708928032327-6BJ8BGYHANTK.png?x-oss-process=image/format,webp',
			title: '兴趣岛中医气血调理训练营',
			userName: '萱萱·53岁',
			contentHtml: '在兴趣岛迷罗老师的课程上，我深刻认识到<strong>不止养生、更要养德、这样才能滋养生命</strong>。养生就是滋养我们的生命力！掌握中医养生的方法就是掌握健康活力的秘诀。知识的魅力不仅仅是滋养自己，更是在掌握和应用于生活中，让我们自身健康的同时，通过我们身体力行的传递，帮助更多人获得健康。',
		},
	];

	// 导师卡片
	const teacherList = [
		'https://media.qianliaowang.com/qlLive/activity/image/88BP9QUO-ONQV-FAD4-1723005428587-LG5OYOY6CIWP.png?x-oss-process=image/format,webp',
		'https://media.qianliaowang.com/qlLive/activity/image/7LKQLMKW-9FBG-EB6U-1723005440286-ZKWMWA4AUGXP.png?x-oss-process=image/format,webp',
		'https://media.qianliaowang.com/qlLive/activity/image/CPMJ9MDM-X72R-K378-1723005426925-M7LXSXRB5LZ5.png?x-oss-process=image/format,webp',
		'https://media.qianliaowang.com/qlLive/activity/image/SIJSZXDD-W6V5-LVZQ-1723005422112-46ZO2VALLXB6.png?x-oss-process=image/format,webp',
		'https://media.qianliaowang.com/qlLive/activity/image/LYP5W4YR-FGSP-24ZS-1723005436509-QGODHIXGY8XD.png?x-oss-process=image/format,webp',
		'https://media.qianliaowang.com/qlLive/activity/image/88BP9QUO-ONQV-FAD4-1723005428587-LG5OYOY6CIWP.png?x-oss-process=image/format,webp',
		'https://media.qianliaowang.com/qlLive/activity/image/1JDVRY52-JS66-H4C5-1723005433956-9L6UQ2YJ88YV.png?x-oss-process=image/format,webp',
		'https://media.qianliaowang.com/qlLive/activity/image/YJ55N46J-4YWE-6QJ5-1723005430475-V9LVT9NHWJJW.png?x-oss-process=image/format,webp',
		'https://media.qianliaowang.com/qlLive/activity/image/23QHA4TD-8B2M-HXI8-1723005417962-27OPDLP5DNLY.png?x-oss-process=image/format,webp',
		'https://media.qianliaowang.com/qlLive/activity/image/SOFE1JP1-N9VQ-DC2J-1723005420047-YDBU14JBT2KA.png?x-oss-process=image/format,webp',
		'https://media.qianliaowang.com/qlLive/activity/image/KH5A8872-L5I3-HQRA-1723005425004-IC2G74G79FXT.png?x-oss-process=image/format,webp',
	];

	const initStyleList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, _idx, arr) => {
		const centerIndex = Math.floor(arr.length / 2) + 1;
		const curSlideIndex = _idx + 1;
		const gap = curSlideIndex - centerIndex;
		const isCenterItem = gap === 0;

		const translateX = isCenterItem ? 0 : gap * 168;
		const translateZ = isCenterItem ? 7 *20 : (7 - Math.abs(gap))*20;
		const rotateY = isCenterItem ? 0 : gap > 0 ? -15 : 15;
		const scale = isCenterItem ? 1.05 : 1;
		return {  transform: `translateX(${translateX}px) translateZ(${translateZ}px) rotateY(${rotateY}deg) scale(${scale})` };
	});
	const [styleList, setStyleList] = useState(initStyleList);
	useEffect(() => {
		const slideAnimate = () => {
			setStyleList((prev) => {
				const before = prev.slice(1, prev.length);
				const after = prev.slice(0, 1);
				return [...before, ...after];
			});
		};
		const interval = setInterval(() => {
			slideAnimate();
		}, 1500);
		return () => {
			clearInterval(interval);
		};
	}, []);

	// 五大准则
	const criterionList = ['必须具备行业顶尖资质', '有多位跟随学习5年以上的学生', '每位课程老师必须从业10年以上', '具备普世教育和尊爱学员的情怀', '有1000+的课程效果口碑'];

	return (
		<div className={`home-brand-info-wrap ${classNames}`}>
			<div className="brand-tab-list">
				{brandTabList.map((item) => {
					return (
						<div className={`brand-tab-list-item ${activeTab === item.id ? 'active'+item.id : ''}`} key={item.id} onMouseOver={() => setActiveTab(item.id)}>
							<div className="tab-title">{item.name}</div>
							<div className="tab-desc">{item.desc}</div>
						</div>
					);
				})}
			</div>
			{activeTab === 1 && (
				<div className="brand-map fadeInUp">
					<img src="https://img.qlchat.com/qlLive/activity/image/IZ2VY6IR-BDKI-P636-1723616710567-6VZJWKM8A4LY.png?x-oss-process=image/format,webp"></img>
				</div>
			)}
			{activeTab === 2 && (
				<div className="teacher-container fadeInUp">
					<img className="bg1" src="https://media.qianliaowang.com/qlLive/activity/image/UQCVWX8F-5QFH-ARTR-1723005320241-W2BL1DJWZM5K.png?x-oss-process=image/format,webp" />
					{criterionList.map((criterion,idx) => {
						return (
							<div className={`criterion style${idx+1}`} key={idx}>
								<img className="icon" src="https://img.qlchat.com/qlLive/activity/image/IX9648SU-I4JP-QTX8-1709606289504-ZX897RNPDVC4.png?x-oss-process=image/format,webp" />
								{criterion}
							</div>
						);
					})}
					<div className="brandInfo-carousel-wrapper">
						{teacherList.map((url: any, idx: number): any => (
							<div className="brandInfo-carousel-item" key={idx} style={styleList[idx]}>
								<img src={url} />
							</div>
						))}
					</div>
					<img className="bg2" src="https://img.qlchat.com/qlLive/activity/image/CED3T2ZI-R5UP-HVDS-1709606284246-9GHYWMPDDSFU.png?x-oss-process=image/format,webp" />
				</div>
			)}
			{activeTab === 3 && (
				<div className="user-comment-container fadeInUp">
					{userCommentList.map((item, index) => {
						return (
							<div className="user-comment-item" key={index}>
								<div className="user-comment-info">
									<img className="user-avatar" src={item.avatar} />
									<div className="user-info">
										<div className="user-name">{item.title}</div>
										<div className="user-tag">{item.userName}</div>
									</div>
								</div>
								<div className="user-comment-content" dangerouslySetInnerHTML={{ __html: item.contentHtml }}></div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
export default BrandInfo;
